function makeProductBoxSameHeight() {
	if ($(window).width() > 576) {	// Chỉ căn bằng nhau trên desktop
		var highestBox = 0;
		var elementArr = [".name"];

		$.each( elementArr, function( key, value ) {
			highestBox = 0;
			$('.product-grid-item ' + value).height('auto');

			$('.product-grid-item ' + value).each(function() {
				if ($(this).height() > highestBox) {
					highestBox = $(this).height();
				}
			});

			$('.product-grid-item ' + value).height(highestBox);
		});
	}
}

function makeNewsBoxSameHeight() {
	if ($(window).width() > 576) {	// Chỉ căn bằng nhau trên desktop
		var highestBox = 0;
		var elementArr = [".name", ".datetime", ".summary"];

		$.each( elementArr, function( key, value ) {
			highestBox = 0;
			$('#home-news ' + value).height('auto');

			$('#home-news ' + value).each(function() {
				if ($(this).height() > highestBox) {
					highestBox = $(this).height();
				}
			});

			$('#home-news ' + value).height(highestBox);
		});
	}
}

function makePostBoxSameHeight() {
	if ($(window).width() > 576) {	// Chỉ căn bằng nhau trên desktop
		var highestBox = 0;
		var elementArr = [".name", ".summary"];

		$.each( elementArr, function( key, value ) {
			highestBox = 0;
			$('.list_post .post ' + value).height('auto');

			$('.list_post .post ' + value).each(function() {
				if ($(this).height() > highestBox) {
					highestBox = $(this).height();
				}
			});

			$('.list_post .post ' + value).height(highestBox);
		});
	}
}

$(document).ready(function($) {
	// Bọc các ảnh trong bài viết với thuộc tính fancybox gallery
	$(".detail_post img").each(function(){
		var src = $(this).attr('src');
		$(this).wrap('<a href="'+src+'" data-fancybox="group"></a>');
	});

	$("[data-fancybox]").fancybox({
		slideShow: true,
		loop : true,
	});

	makeProductBoxSameHeight();
	makeNewsBoxSameHeight();
	makePostBoxSameHeight();

	$(window).on('resize', function(){
		makeProductBoxSameHeight();
		makeNewsBoxSameHeight();
		makePostBoxSameHeight();
	});

	// Điều khiển row trên bảng có thể click như link, sử dụng thuộc tính data-href
	$(".clickable-row").on('click', function() {
			window.location = $(this).data("href");
	});
});
